<template>
  <div>
    <h1>员工扫码签到/签退系统</h1>
    <div class="test">一个演示环境</div>
    <div v-if="!scanning">
      <button @click="startScanning">开始扫码</button>
    </div>
    <div v-else>
      <video ref="video"
             width="300"
             height="200"
             controls="controls"></video>
      <canvas ref="canvas"
              style="display: none;"></canvas>
      <button @click="stopScanning">停止扫码</button>
    </div>
    <div>code信息是：{{ codeInfo }}</div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import axios from "axios";
import jsQR from "jsqr";
import {CancelToken} from "axios"; // 导入CancelToken
export default {
  data() {
    return {
      scanning: false,
      message: "",
      userId: "12345", // 替换为实际的员工ID
      cancelToken: null, // 添加cancelToken属性
      codeInfo: {},
      controls: false
    };
  },
  methods: {
    startScanning() {
      this.scanning = true;
      setTimeout(() => {
        const video = this.$refs.video;
        this.controls = false;
        video.addEventListener('load', () => {
          this.controls = false;
        });
        navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}})
            .then(stream => {
              video.srcObject = stream;
              video.play();
              this.captureFrame();
            })
            .catch(error => {
              console.error(error);
            });
      }, 1000);
    },
    captureFrame() {
      if (!this.scanning) {
        return;
      }
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        this.codeInfo = code.data;
        this.stopScanning();
        this.sendQRCodeData(code.data);
      } else {
        requestAnimationFrame(this.captureFrame);
      }
    },
    stopScanning() {
      this.scanning = false;
      const video = this.$refs.video;
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    },
    sendQRCodeData(data) {
      if (this.cancelToken) {
        this.cancelToken.cancel(); // 取消之前的请求
      }
      this.cancelToken = CancelToken.source(); // 创建新的cancelToken
      axios.post("/api/checkinout", {
        userId: this.userId,
        qrCodeData: data,
      }, {
        cancelToken: this.cancelToken.token // 将cancelToken添加到请求配置中
      })
          .then(response => {
            this.message = response.data.message;
          })
          .catch(error => {
            console.error(error);
          });
    },
  },
  mounted() {
    this.$nextTick(() => {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      console.log(video, canvas);
    });
  }
};
</script>
<style scoped>
.test{
  width: 100%;
  height: 100%;
  color: red;
}
</style>
