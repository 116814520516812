<template>
  <div>
    <label>输入二维码内容文本：</label>
    <input v-model="qrData"
           type="text"/>
    <button class="generate-qr-code"
            @click="generateQRCode">生成可以扫描的二维码
    </button>
    <div>
      <div>
        <label>二维码颜色：</label>
        <input type="color"
               v-model="colorVal"
               @input="colorChange"/>
      </div>
      <div>
        <label>背景颜色：</label>
        <input type="color"
               v-model="backgroundColorVal"
               @input="backgroundColorChange"/>
      </div>
    </div>
    <canvas ref="qrCanvas"
            :width="size"
            :height="size"></canvas>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    size: {
      type: Number,
      default: 10
    },
    errorCorrection: {
      type: String,
      default: "L"
    }
  },
  data() {
    return {
      qrInstance: null,
      qrData: "",
      colorVal: "#11d41e",
      backgroundColorVal: "#e3eccf"
    };
  },
  methods: {
    generateQRCode() {
      if (!this.qrData) {
        return;
      }
      const qrCanvas = this.$refs.qrCanvas;
      const options = {
        width: 200,
        height: 200,
        color: {
          dark: this.colorVal,  // 设置深色的颜色
          light: this.backgroundColorVal  // 设置浅色的颜色
        },
      };
      QRCode.toCanvas(qrCanvas, this.qrData, options, (error) => {
        if (error) {
          console.error(error);
          return;
        }
        this.qrInstance = qrCanvas;
      });
    },
    colorChange() {
      if (this.qrInstance) {
        this.qrInstance.getContext("2d").fillStyle = this.colorVal;
      }
      console.log(this.colorVal);
    },
    backgroundColorChange() {
      console.log(this.backgroundColorVal);
    }
  }
};
</script>
<style scoped>
.generate-qr-code {
  margin-left: 10px;
}
</style>
