<template>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <ScanQrPage/>
  <QRCode/>
</template>

<script>
import ScanQrPage from "@/components/main/ScanQrPage.vue";
import QRCode from "@/components/main/QRCode.vue";

export default {
  name: "App",
  components: {
    ScanQrPage,
    QRCode
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
